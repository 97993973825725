import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  :root {
    --color-gray: hsl(0, 0%, 27%);

    --color-yellow-400: #d2b200;
    --color-yellow-500: #dfbf00;
    --color-yellow-600: #edcc00;
    --color-yellow-700: #fad900;
    --color-red-400: #d7373f;
    --color-red-500: #e34850;
    --color-red-600: #ec5b62;
    --color-red-700: #f76d74;
    --color-green-400: #268e6c;
    --color-green-500: #2d9d78;
    --color-green-600: #33ab84;
    --color-green-700: #39b990;
    --color-blue-400: #1473e6;
    --color-blue-500: #2680eb;
    --color-blue-600: #378ef0;
    --color-blue-700: #4b9cf5;
    --color-black: #000;
    --color-gray-50: #080808;
    --color-gray-75: #1a1a1a;
    --color-gray-100: #1e1e1e;
    --color-gray-200: #2c2c2c;
    --color-gray-300: #393939;
    --color-gray-400: #494949;
    --color-gray-500: #5c5c5c;
    --color-gray-600: #7c7c7c;
    --color-gray-700: #a2a2a2;
    --color-gray-800: #c8c8c8;
    --color-gray-900: #efefef;
    --color-white: #fff;
    --type-system: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

    --body-background: var(--color-white);
    --material-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    --primary-font: var(--type-system);
    --radius: 4px;
  }

  html {
    box-sizing: border-box;
    font-size: 100%;

    /* @media screen and (min-width: 64em) { font-size: 120%; } */

    @supports (font-kerning: normal)
      and (font-varient-ligatures: common-ligatures contextual)
      and (font-variant-numeric: oldstyle-nums proportional-nums) {
      font-feature-settings: normal;
      font-kerning: normal;
      font-variant-ligatures: common-ligatures contextual;
      font-variant-numeric: oldstyl-nums proportional-nums;
    }
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  *:focus {
    outline: 1px dotted currentColor;
  }

  body {
    background-color: var(--body-background);
    font-family: var(--primary-font);
    font-weight: normal;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  [hidden] { display: none; }

  abbr {
    font-feature-settings: 'kern', 'liga', 'clig', 'calt', 'c2sc', 'smcp';
    @supports (font-variant-caps: all-small-caps) {
      font-feature-settings: normal;
      font-variant-caps: all-small-caps;
    }
  }

  b, strong {
    font-weight: bold;
  }

  blockquote {
    border-bottom: 1px solid var(--color-gray-400);
    border-top: 1px solid var(--color-gray-400);
    color: var(--color-red);
    font-style: italic;
    line-height: 1.4;
    margin: 0;
    padding: 1rem 0;
  }

  dt {
    font-weight: bold;
  }

  i, em {
    font-style: italic;
  }

  img, object, video { max-width: 100%; }

  small {
    display: inline-block;
    line-height: 1.1;
  }

  svg use { pointer-events: none; }

  .visually-hidden {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  }
`

export default GlobalStyle
